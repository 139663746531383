import axios from '@/libs/axios'

class User {
  static myProfile (params) {
    return axios.post('user/me', params)
  }

  static getSchedule (params) {
    return axios.post('user/schedule', params)
  }

  static update (params) {
    return axios.post('user/me/update', params)
  }

  static demoGetSchedule (params) {
    return axios.post('demo/user/schedule', params)
  }

  static forgotPassword (params) {
    return axios.post('auth/user/forgot-password', params)
  }

  static validateToken (params) {
    return axios.post('auth/user/forgot-password/validate-token', params)
  }

  static changePassword (params) {
    const config = {
      headers: { Authorization: `Bearer ${params.accessToken}` }
    }
    const bodyParameters = {
      password: params.password,
      email: params.email
    }
    return axios.post('auth/user/change-password', bodyParameters, config)
  }
}

export default User
