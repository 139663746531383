<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>My page</h2>
          </div>
          <section class="study_myinfo_list_area">
            <div class="mypage_scroll_content">
              <section class="study_main_box">
                <!-- content -->
                  <!-- 시리즈 학습일정 -->
                  <div style="width:100%;overflow:hidden;">
                  <div class="mypage_top_btn_wrap">
                    <!-- 포인트 잔액 -->
                    <div class="mypage_bank_btn_wrap">
                    <div class="mypage_bank_btn">
                      <div class="mypage_bank_h4_title">
                        <h4>포인트 잔액</h4>
                      </div>
                      <div class="mypage_bank_num">{{ balance }}</div>
                    </div>
                    </div>
                    <!-- //포인트 잔액 -->
                    <!-- 포인트 충전 -->
                    <div class="mypage_charge_btn_wrap">
                      <a href="#" @click.prevent="$router.replace({ name: 'my-page.purchase-credit', query: { redirect: 'my-page.point02' } })">
                        <div class="mypage_charge_btn">
                          <div class="mypage_charge_h4title">포인트 충전</div>
                        </div>
                      </a>
                    </div>
                    <!-- //포인트 충전 -->
                  </div>
                  <!-- 포인트 사용내역 -->
                  <div class="mypage_use_list">
                    <div class="mypage_use_title">
                      포인트 사용내역
                    </div>
                    <ul>
                      <li>
                        <a class="mypage_use_list_text" href="#">s5 수강료</a>
                        <div class="mypage_use_list_btn">05.15</div>
                      </li>
                      <li>
                        <a class="mypage_use_list_text" href="#">s5 수강료</a>
                        <div class="mypage_use_list_btn">05.15</div>
                      </li>
                      <li>
                        <a class="mypage_use_list_text" href="#">s5 수강료</a>
                        <div class="mypage_use_list_btn">05.15</div>
                      </li>

                    </ul>
                  </div>
                  </div>
                  <!--// 포인트 사용내역 -->
                  <!-- 캐쉬백 포인트 내역 -->
                  <div class="cashback_wrap_list">
                    <div class="cashback_wrap_title">
                      <h4>캐쉬백 포인트 내역</h4>
                    </div>
                    <ul>
                      <li>
                        <a href="#">1000 point 충전</a>
                      </li>
                      <li>
                        <a href="#">1000 point 충전</a>
                      </li>
                      <li>
                        <a href="#">1000 point 충전</a>
                      </li>
                      <li>
                        <a href="#">1000 point 충전</a>
                      </li>
                      <li>
                        <a href="#">1000 point 충전</a>
                      </li>
                    </ul>
                  </div>
                  <!-- //캐쉬백 포인트 내역 -->
                  <!-- 포인트 충전, 사용내역 -->
                  <div class="cashback_all_wrap">
                    <!-- 포인트 충전내역 -->
                    <div class="cashback_all_01">
                      <div class="cashback_all_title01">
                        <h4>포인트 충전 내역</h4>
                      </div>
                      <ul>
                        <li
                          v-for="(load, loadIndex) in loads"
                          :key="`load-${loadIndex}`"
                        >
                          <a href="#">{{ load.amount }} point 충전</a>
                        </li>
                      </ul>
                    </div>
                    <!-- //포인트 충전내역 -->
                    <!-- 포인트 사용내역 -->
                    <div class="cashback_all_02">
                      <div class="cashback_all_title02">
                        <h4>포인트 사용내역 전체</h4>
                      </div>
                      <ul>
                        <li>
                          <a href="#">1000 point 사용</a>
                        </li>
                        <li>
                          <a href="#">1000 point 사용</a>
                        </li>
                        <li>
                          <a href="#">1000 point 사용</a>
                        </li>
                        <li>
                          <a href="#">1000 point 사용</a>
                        </li>
                        <li>
                          <a href="#">1000 point 사용</a>
                        </li>
                      </ul>
                    </div>
                    <!-- //포인트 사용내역 -->
                  </div>
                  <!-- //포인트 충전, 사용내역 -->
                <!-- //content -->
              </section>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'my-page' }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>
import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import { onMounted, ref } from '@vue/composition-api'
import User from '@/models/User'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  name: 'MyPage.Point02',
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  },
  setup () {
    const balance = ref(0)
    const loads = ref([])

    const loadMyProfile = async () => {
      try {
        const response = await User.myProfile()
        loads.value = response.data.loads
        balance.value = response.data.amount
      } catch (e) {
        // Do Nothing Here ...
      }
    }

    onMounted(loadMyProfile)

    return {
      balance,
      loads
    }
  }
}
</script>

<style scoped>

</style>
